<template>
  <div class="preuve-destruction-edit-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <div class="title-with-button">
          <h2>Preuves de destruction</h2>
          <Btn
            color="primary"
            hollow
            text="Ajouter une preuve de destruction"
            @click="openModal('addDestructionProof', proof)"
          />
        </div>
      </Container>
    </div>

    <Section>
      <List
        :api="list.api"
        :header="list.headers"
        :items="list.col"
        :item-key="list.key"
      >
        <template v-slot:group-actions="{ selectedItems }">
          <Btn
            text="Supprimer"
            icon="trash-alt"
            color="default"
            @click="openModal('deleteItems', selectedItems)"
          />
        </template>
        <template v-slot:media="{ item }">
          {{ item?.media?.nom }}
        </template>
        <template v-slot:actions="{item}">
          <Btn
            icon="visibility"
            default
            round
            grow
            @click="openModal('destructionProof', item?.media)"
          />
          <Btn
            icon="trash-alt"
            default
            round
            grow
            @click="openModal('deleteItem', item)"
          />
        </template>
      </List>
    </Section>
  </div>

  <Modal
    title="Voir la preuve de destruction"
    :active="modal.destructionProof"
    :data="modalData"
    @modal-close="modal.destructionProof = false"
  >
    <template v-slot:modal-body="{ data }">
      <ImageToken
        :image="data"
        :alt="data?.nom"
        defaultImage
        size="full"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Fermer" @click="modal.destructionProof = false" />
    </template>
  </Modal>
  <!-- Modal Preuve de destruction -->
  <Modal
    title="Ajouter une preuve de destruction"
    :active="modal.addDestructionProof"
    :data="modalData"
    @modal-close="modal.addDestructionProof = false"
  >
    <template v-slot:modal-body="{ data }">
      <InputMedia
        :key="componentKey"
        textInfo="Fichier au format JPG, 5Mo maximum"
        defaultImage
        type="photo"
        :media="data?.media"
        @update:modelValue="setMedia($event)"
      />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.addDestructionProof = false" />
      <Btn
        text="Ajouter"
        @click="handleSubmit()"
        color="primary"
        type="submit"
      />
    </template>
  </Modal>

  <Modal
    title="Supprimer la preuve de destruction&nbsp;?"
    :active="modal.deleteItem"
    :data="modalData"
    @modal-close="modal.deleteItem = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer cette preuve de destruction&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItem = false" />
      <Btn
        text="Supprimer"
        @click="deleteItem(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <Modal
    title="Supprimer les preuves de destruction&nbsp;?"
    :active="modal.deleteItems"
    :data="modalData"
    @modal-close="modal.deleteItems = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data?.length }} preuves de destruction&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn
        text="Supprimer"
        @click="deleteItems(data)"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'
import InputMedia from '@/components/form/InputMedia.vue'
import ImageToken from '@/components/base/ImageToken.vue'

export default {
  name: 'DestructionProofSubview',
  components: {
    ImageToken,
    InputMedia,
    Btn,
    List,
    Container,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    return {
      modal: {
        deleteItem: false,
        deleteItems: false,
        destructionProof: false,
        addDestructionProof: false,
      },
      modalData: {},

      // Proof
      proof: {},
      proofMedia: null,

      list: {
        api: {
          endpoint: null,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom' },
        ],
        col: ['media'],
        key: 'id',
      },

      componentKey: 0,
    }
  },
  created() {
    this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`).then(() => {
    })
    this.list.api.endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/destruction/preuve`
  },
  methods: {
    openModal(modal, datas) {
      this.modalData = datas
      this.modal[modal] = true
    },

    setMedia(event) {
      this.proofMedia = event
    },

    handleSubmit() {
      const { params } = this.$route

      this.emitter.emit('open-loader')
      this.fetchService.post(`essai/${params.id}/evenement/${params.tid}/fiche/destruction/preuve`, {
        media_id: this.proofMedia.id,
      })
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La preuve de destruction a bien été ajoutée.',
          })

          this.componentKey += 1
          this.proofMedia = null
          this.modal.addDestructionProof = false
          this.emitter.emit('list-refresh')
          this.emitter.emit('close-loader')
        })
    },

    deleteItem(item) {
      const { params } = this.$route

      this.emitter.emit('open-loader')
      this.fetchService
        .delete(`essai/${params.id}/evenement/${params.tid}/fiche/destruction/preuve/${item.id}`)
        .then(
          () => {
            this.emitter.emit('list-refresh')
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La preuve de destruction a été supprimée avec succès.',
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )

      this.emitter.emit('close-loader')
      this.modal.deleteItem = false
    },

    deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      itemsToDelete.forEach((item) => {
        const { params } = this.$route
        this.fetchService.delete(
          `essai/${params.id}/evenement/${params.tid}/fiche/destruction/preuve/${item.id}`,
        ).then(
          () => {
            this.emitter.emit('list-refresh')
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La preuve de destruction a été supprimée avec succès.',
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.modal.deleteItems = false
      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
    },
  },
}
</script>
